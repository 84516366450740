import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import BlogItem from "../components/blogItem";
import { Link } from "gatsby";
import BackToPage from "../images/back-to-page.png";
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],

            isAwardLoaded: false,
            awardContent: [],

            isCategoryLoaded: false,
            categoryContent: [],
            author_name: null

        }
    }

    async componentDidMount() {

        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let name = id.params["*"];

        this.setState({ author_name: name })
        this.getAllCategoryByType();

        this.getAllBlog(name);
    }

    componentWillReceiveProps(nextProps) {

        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let name = nextProps.params["*"];
        this.setState({ author_name: name })
        this.getAllCategoryByType();
        this.getAllBlog(name);
    }

    getAllBlog(name) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-author/" + name)
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
                    console.log("filteredDates", filteredDates)
                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    getAllCategoryByType() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: result
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }



    render() {
        const { isBlogLoaded, blogContent, author_name } = this.state;
        return (
            <Layout>

                {isBlogLoaded ?
                    <Nav
                        active="blog"
                        pageTitle={'Blog By ' + author_name}
                        title={''}
                        description={''}
                        keywords={''}
                    ></Nav>
                    : ''}
                <Intro title="Our Blog" search="true"
                        searchType="blog" description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance."> </Intro>


                <div className="main-container">
                    <div className="container">
                        {isBlogLoaded ?
                            <div className="author-info">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <p><Link to="/blog" className="author_link">  <img className="icon" src={BackToPage} title="" alt="" /> <span>{author_name}</span> </Link></p>
                                        <p className="count"> {blogContent.length} blog posts by <span>{author_name}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        <div className="row" id="authorPost">
                            <div className="col-md-12 col-sm-12">
                                <div className="blog-container">
                                    {isBlogLoaded ?
                                        <React.Fragment>
                                            {blogContent.map(item => (
                                                <div className="" key={item._id}>
                                                    <BlogItem
                                                        category_show="true"
                                                        category={item.category}
                                                        title={item.title}
                                                        author={item.created_by}
                                                        date={item.publish_date}
                                                        short_content={item.short_description}
                                                        slug={item.slug}
                                                        description={item.description}
                                                    >
                                                    </BlogItem>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;