import React from "react";
import { Link } from "gatsby";
import Date from './date';

export default function BlogItem(props) {
    return (
        <React.Fragment>
            <div className={props.category_show == "true" ? 'blog-item with-category' : 'blog-item'}>
                <div className="inner">
                    {props.category_show == "true" ?
                        <span className="category">
                            <Link title={props.category} className="category_title" to={"/blog-by-category/" + props.category}>
                                <span dangerouslySetInnerHTML={{ __html: props.category }} /></Link>
                        </span> : ''}
                    {props.blog_details == "true" ? <h1><Link to={"/blog-details/" + props.slug}> {props.title} </Link></h1> : <h2><Link to={"/blog-details/" + props.slug}> {props.title} </Link></h2>}
                    <p className="author">
                        <Link to={"/blog-by-author/" + props.author}>{props.author}</Link>
                        <Date date={props.date}></Date>
                    </p>
                    {props.blog_details == "true" ? <hr></hr> : ''}

                    <div className="short_content">
                        {props.short_content.length == 0 ?
                            <React.Fragment>
                                {props.description == undefined ? ' ' : <div dangerouslySetInnerHTML={{ __html: props.description.slice(0, 300) + "..." }} />}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div dangerouslySetInnerHTML={{ __html: props.short_content }} />
                            </React.Fragment>
                        }
                        {props.tag ?
                            <ul className="tags">
                                {props.tag.map((data) => {
                                    return (
                                        <li key={data.key}>
                                            <Link to={"/blog-by-tags/" + data}>{data}</Link>
                                        </li>
                                    );
                                })
                                }
                                <div className="clearfix"></div>
                            </ul>
                            : ''}
                        {props.download_show == "true" ?
                            <ul className="download">
                                <li><a href={props.image} target="_blank">Download </a> </li>
                            </ul>
                            : ''}

                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}
